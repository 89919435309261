import { render, staticRenderFns } from "./purchase-order-shipment.vue?vue&type=template&id=940c8242&scoped=true&"
import script from "./js/purchase-order-shipment.js?vue&type=script&lang=js&"
export * from "./js/purchase-order-shipment.js?vue&type=script&lang=js&"
import style0 from "./purchase-order-shipment.vue?vue&type=style&index=0&id=940c8242&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940c8242",
  null
  
)

export default component.exports