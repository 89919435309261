import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
import reportService from "./reports-service";
export default {
  name: "purchaseOrderShipment",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      specificSelected: "",
      showData: false,
      hideSpecific: false,
      showGo: true,
      disableDate: false,
      showTable: false,
      showPart: false,
      partNum: "",
      partDesc: "",
      mainPage: "",
      excelName: "",
      page: 1,
      displayCost: "",
      totalPages: 0,
      subPage: "",
      restForm: false,
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      sDate: "",
      eDate: "",
      selectedProject: "",
      selectedBusiness: "",
      selectedWarehouse: "",
      selectedClass: "",
      selectedOem: "",
      selectedPODetails: "",
      partSearchKey: "",
      partResultList: [],
      showPartNumDetails: false,
      businessList: [],
      detailsData: [],
      warehouseList: [],
      classList: [],
      uniqueClassList: [],
      uniqueOEM: [],
      oemList: [],
      poList: [],
      uniquePODetails: [],
      reportData: [],
      startDate: false,
      showWarehouse: false,
      showClass: false,
      showOem: false,
      poType: false,
      showSearch: false,
      exportDialog: false,
      export50kData: false,
      showStartDate: true,
      showEndDate: true,
      endDate: false,
      showAllForm: false,
      debouncedGetPartList :[],
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      projectList: [],
      totalRecords: 0,
      filterObj: {
        user_id: 0,
        pass: 0,
        lcsdate: "",
        lcedate: "",
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        potype_id: 0,
        showcost: false,
        RecordCount: 0,
        PageNumber: 1,
        export: 0,
        guid: "",
      },
      headerReport: [
        { text: "Project", align: "start", value: "Project", class: "primary customwhite--text" },
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "Po No", value: "PO_Number", class: "primary customwhite--text" },
        { text: "Line No", value: "Line_No", class: "primary customwhite--text" },
        { text: "Vendor SO", value: "Vend_SO", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Order No", value: "Order_No", class: "primary customwhite--text" },
        { text: "VRMA", value: "VRMA", class: "primary customwhite--text" },
        { text: "Vendor ID", value: "Vendor_ID", class: "primary customwhite--text" },
        { text: "Vendor Name", value: "Vendor_Name", class: "primary customwhite--text" },
        { text: "Item", value: "Item", class: "primary customwhite--text", width: "10%" },
        { text: "Model", value: "Model", class: "primary customwhite--text" },
        { text: "Class", value: "Class", class: "primary customwhite--text" },
        { text: "Unit Cost", value: "Unit_Cost", class: "primary customwhite--text" },
        { text: "Qty Ordered", value: "Qty_Ord", class: "primary customwhite--text" },
        { text: "Qty Shipped", value: "Qty_Ship", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial No", value: "Serial_No", class: "primary customwhite--text" },
        { text: "Date Shipped", value: "Date_Ship", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order_Type", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.filterObj.user_id = this.userId;
    this.debouncedGetPartList  = Utility.debounce(this.getPartList, 500);
 
  },
  mounted() {},
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter?.length == 1 ? true : false;
      } else return false;
    },
    async exportData() {
      this.export50kData = false;
      this.exportDialog = false;
    },

    // to export the Execel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.filterObj.export = 1;
        this.excelName =
          "Po_Shipment_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/purchase_order_shipments", this.filterObj);
        let responseData = JSON.parse(response.data.body.message);
        this.export50kData = false;
        responseData?.forEach((element) => {
          element.Date_Ship = Utility.convertESTToLocal(element.Date_Ship);
          element.Order_Date = Utility.convertESTToLocal(element.Order_Date);
        });
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //File Name for Export

    // on CLick COntinue
    async onClickContinue() {
      this.filterObj.pass = 0;
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.filterObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.filterObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      let projectData = await reportService.purchaseOrderShipment("post", this.filterObj, false);
      if (projectData?.length > 0) {
        this.projectList = projectData;
        this.restForm = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.restForm = false;
      }
    },
    //on Change Project
    onChangeProject() {
      this.filterObj.proj_id = this.selectedProject;
      this.filterObj.pass = 1;
      this.showAllForm = false;
      this.disableDate = true;
      this.showGo = false;
      this.showPart = true;
      this.getBusinessData();
    },
    async getBusinessData() {
      let businessData = await reportService.purchaseOrderShipment("post", this.filterObj, false);
      this.businessList = businessData;
    },
    async onChangeBusiness() {
      this.hideSpecific = true;
      this.filterObj.pass = 2;
      this.filterObj.bu_id = this.selectedBusiness;
      this.detailsData = await reportService.purchaseOrderShipment("post", this.filterObj, false);
      const unquieWarehouse = [...new Map(this.detailsData.map((item) => [item["ware"], item])).values()];
      this.warehouseList = unquieWarehouse.map((x) => ({
        ware: x.ware,
        ware_id: x.ware_key,
      }));
      this.showWarehouse = true;
      this.showClass = false;
      this.showOEM = false;
      this.poType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.classList = [];
      this.oemList = [];
      this.poList = [];
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedPODetails = "";
    },
    onChangeWarehouse(ware_id) {
      this.uniqueClassList = this.detailsData.filter((x) => x.ware_key == ware_id);
      const uniqueClass = [...new Map(this.uniqueClassList.map((item) => [item["class"], item])).values()];
      this.classList = uniqueClass.map((x) => ({
        class: x.class,
        class_id: x.class_key,
      }));
      this.showClass = true;
      this.showOEM = false;
      this.poType = false;
      this.displayCost = false;
      this.showData = false;
      this.showSearch = false;
      this.oemList = [];
      this.poList = [];

      this.showCost = false;
      this.selectedPODetails = "";
      this.selectedClass = "";
      this.selectedOem = "";
    },
    onChangeClass(class_id) {
      this.uniqueOEM = this.uniqueClassList.filter((x) => x.class_key == class_id);
      const uniqueClass = [...new Map(this.uniqueOEM.map((item) => [item["oem"], item])).values()];
      this.oemList = uniqueClass.map((x) => ({
        oem: x.oem,
        oem_id: x.oem_key,
      }));

      this.showOEM = true;
      this.poType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.showData = false;
      this.poList = [];
      this.showCost = false;
      this.selectedPODetails = "";
      this.selectedOem = "";
    },
    onChangeOEM(oem_id) {
      this.uniquePODetails = this.uniqueClassList.filter((x) => x.oem_key == oem_id);
      const uniqueSoDesc = [...new Map(this.uniquePODetails.map((item) => [item["po_desc"], item])).values()];
      this.poList = uniqueSoDesc.map((x) => ({
        po_desc: x.po_desc,
        po_type_id: x.potype_key,
      }));
      this.poType = true;
      this.displayCost = true;
      this.showData = false;
      this.showSearch = false;
      this.showCost = false;
      this.selectedPODetails = "";
    },

    async onClickSearch(PageNumber) {
      (this.filterObj.pass = 3), (this.filterObj.PageNumber = PageNumber);
      (this.filterObj.bu_id = this.selectedBusiness),
        (this.filterObj.class_id = this.selectedClass),
        (this.filterObj.ware_id = this.selectedWarehouse),
        (this.filterObj.oem_id = this.selectedOem),
        (this.filterObj.potype_id = this.selectedPODetails);
      this.filterObj.part_num = this.partSearchKey;
      let tableData = await reportService.purchaseOrderShipment("post", this.filterObj, false);
      this.reportData = tableData?.Resultset;
      if (this.reportData?.length > 0) {
        this.showData = true;
        this.reportData?.forEach((element) => {
          element.Date_Ship = Utility.convertESTToLocal(element.Date_Ship);
        });
      } else {
        this.reportData = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Record Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
      this.$vuetify.goTo(0);
      this.showTable = true;
      
      this.totalRecords = tableData?.TotalRecords;
      this.totalPages = tableData?.TotalPages;
    },
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      (this.partNum = ""), (this.showData = false);
      this.disableDate = false;
      this.showTable = false;
      this.showGo = true;
      (this.partDesc = ""), (this.selectedBusiness = "");
      this.partSearchKey = "";
      this.restForm = false;
      this.showPart = false;
      this.showWarehouse = false;
      this.specificSelected = false;
      this.showClass = false;
      this.showOEM = false;
      this.poType = false;
      this.displayCost = false;
      this.showAllForm = false;
      this.showSearch = false;
      this.businessList = [];
      this.projectList = [];
      this.warehouseList = [];
      this.oemList = [];
      this.classList = [];
      this.oemList = [];
      this.poList = [];
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedPODetails = "";
      this.hideSpecific = false;
      this.reportData = [];
      this.totalRecords = 0;
      this.totalPages = 1;
      this.filterObj = {
        user_id: this.userId,
        pass: 1,
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        potype_id: 0,
        showcost: false,
        RecordCount: 0,
        PageNumber: 1,
        export: 0,
        guid: "",
      };
    },
    onClickSpecificPartNumber() {
      this.specificSelected = true;
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList(){
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };          
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },

   async  showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
   
      this.filterObj.part_num=this.partNum;
      let dataExists=await reportService.purchaseOrderShipment("post", this.filterObj, false);
      if(!dataExists){
        this.showAllForm = false;
      }
      else{
        this.showAllForm = true;
      }
    },

    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
